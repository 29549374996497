<template>
	<el-select
		v-model="model"
		collapse-tags
		filterable
		clearable
		remote
		reserve-keyword
		placeholder=" "
		:remote-method="remoteModel"
		:loading="loading"
		@clear="clear"
		@change="handleChange"
	>
		<!-- <el-checkbox
			v-model="checked"
			@change="handleCheckAllChange"
			label="全选"
			size="large"
			style="margin-left: 20px;height: 34px;line-height: 34px;"
			:indeterminate="model.length < options.length && model.length !== 0"
		/> -->
		<el-option
			v-for="item in options"
			:key="item.value"
			:label="item.label"
			:value="item.value"
		/>
	</el-select>
</template>

<script>
export default {
	name: 'SearchInnerModel',
	props: ['modelValue'],
	emits: ['update:modelValue'],
	data() {
		return {
			loading: false,
			checked: false,
			options: [],
		}
	},
	created() {
		this.remoteModel()
	},
	computed: {
		model: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			},
		},
	},
	methods: {
		remoteModel(query) {
			this.loading = true
			this.api
				.ProductModelList({
					page: 1,
					limit: 100,
					kname: query || '',
				})
				.then((res) => {
					if (res.data.code == 200) {
						this.options = res.data.data.data.map((item) => ({
							value: item.code,
							label: item.name,
						}))
						this.loading = false
						return
					}
					this.loading = false
					this.$message.error('获取失败' + res.data.msg)
				})
		},
		handleCheckAllChange() {
			if (this.checked) {
				this.model = this.options.map((item) => item.value)
			} else {
				this.model = []
			}
		},
		handleChange() {
			this.$emit('selectQuery')
		},
		clear() {
			this.remoteModel('')
		},
	},
}
</script>
