<template>
	<div class="container">
		<div class="search" style="display:flex">
			<el-form
				ref="queryFormRef"
				style="flex:1"
				:inline="true"
				:model="search"
				class="demo-form-inline"
				size="mini"
			>
				<el-form-item label="外部型号：" prop="product_id">
					<TuyaSearchOuterModel
						v-model="search.product_id"
						@selectQuery="Search"
					></TuyaSearchOuterModel>
				</el-form-item>
				<el-form-item label="品牌：" prop="brand_name">
					<SearchBrandList
						v-model="search.client_brand_code"
						@selectQuery="Search"
					></SearchBrandList>
				</el-form-item>
				<el-form-item label="设备状态：" prop="p2p_online">
					<el-select
						v-model="search.p2p_online"
						placeholder=" "
						clearable
						@change="Search"
					>
						<el-option label="在线" :value="1" />
						<el-option label="不在线" :value="2" />
					</el-select>
				</el-form-item>
				<el-form-item label="设备id：" prop="device_id">
					<el-input
						v-model.trim="search.device_id"
						placeholder=" "
						@keyup.enter.native="Search"
					></el-input>
				</el-form-item>
				<el-form-item label="设备uuid：" prop="uuid">
					<el-input
						v-model.trim="search.uuid"
						placeholder=" "
						@keyup.enter.native="Search"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-button
						type="primary"
						style="margin-left: 16px;"
						class="btnClass"
						@click="Search"
						>搜索</el-button
					>
					<el-button
						type="text"
						class="textBtn"
						style="padding-bottom: 0px;vertical-align: bottom;"
						@click="clearCondition"
						>重置</el-button
					>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<el-table :data="list" size="mini">
			<el-table-column
				label="设备ID"
				width="250"
				prop="device_id"
			></el-table-column>
			<el-table-column
				label="设备UUID"
				width="250"
				prop="uuid"
			></el-table-column>
			<el-table-column
				label="设备名称"
				prop="name"
				width="180"
			></el-table-column>
			<el-table-column
				label="所属产品型号"
				prop="product_name"
			></el-table-column>
			<el-table-column
				label="创建时间"
				prop="create_time"
				:formatter="
					(row) => row.create_time && util.dateFormat(row.create_time)
				"
				width="180"
			>
			</el-table-column>
			<el-table-column label="设备状态" width="100">
				<template v-slot="scope">
					<span style="color:#36C626" v-if="scope.row.online == 1">在线</span>
					<span style="color:#FF1A2E" v-else>不在线</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="150px">
				<template v-slot="scope">
					<el-button
						type="info"
						size="mini"
						class="btnClass"
						@click="tuyaInfo(scope.row)"
						>详情</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@current-change="handleCurrentChange"
			v-model:currentPage="search.page"
			:page-size="search.limit"
			layout="total, prev, pager, next"
			:total="total"
			background
		>
		</el-pagination>
		<!-- 涂鸦详情 -->
		<el-dialog title="涂鸦设备详情" v-model="tuyaFormVisible" width="800px">
			<el-form :model="tuyaform" inline label-width="100px">
				<el-form-item label="产品型号：">
					<el-input
						disabled
						v-model="tuyaform.model"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="产品id：">
					<el-input
						disabled
						v-model="tuyaform.product_id"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="设备名称：">
					<el-input
						disabled
						v-model="tuyaform.name"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="所属用户：">
					<el-input
						disabled
						v-model="tuyaform.tuyauid"
						autocomplete="off"
					></el-input>
					<el-button
						type="text"
						style="position: absolute;margin-left: 10px;"
						@click="getUserDetailByTuyaUid(tuyaform.tuyauid)"
						>详情</el-button
					>
					<!-- <router-link
						style="margin-right:10px;"
						:to="'/iot/userinfo/' + scope.row.uid + '&' + scope.row.region"
					>
						
					</router-link> -->
				</el-form-item>
				<el-form-item label="设备UUID：">
					<el-input
						disabled
						v-model="tuyaform.uuid"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="设备IP：">
					<el-input
						disabled
						v-model="tuyaform.ip"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="在线状态：">
					<el-input
						disabled
						v-model="tuyaform.online"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="初次配网时间：">
					<el-input
						disabled
						v-model="tuyaform.create_time"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="上次配网时间：">
					<el-input
						disabled
						v-model="tuyaform.active_time"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="状态更新时间：">
					<el-input
						disabled
						v-model="tuyaform.update_time"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="所在时区：">
					<el-input
						disabled
						v-model="tuyaform.time_zone"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="产品类别：">
					<el-input
						disabled
						v-model="tuyaform.category"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="密钥：">
					<el-input
						disabled
						v-model="tuyaform.local_key"
						autocomplete="off"
					></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="tuyaFormVisible = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script type="text/ecmascript-6">

import TuyaSearchOuterModel from '../../../components/device/TuyaSearchOuterModel.vue'
import SearchBrandList from '../../../components/device/SearchBrandList.vue'
import util from '../../../util/util'

export default {
  name: '',
  mixins: [],
  components: {
    TuyaSearchOuterModel,
    SearchBrandList,
  },
  props: {},
  data () {
    return {
	  userInfo: {},
      util: util,
      search: {
        page: 1,
        limit: 16,
        product_id: '',
        client_brand_code: '',
        p2p_online: '',
        uuid: '',
        device_id: '',
      },
      list: [],
      total: 0,
      tuyaFormVisible: false,
      tuyaform: {},
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search(){
      this.api.DeviceTuyaList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    },
    clearCondition() {
      this.reset()
	  this.Search()
    },
    reset() {
      this.search = {
        ...this.search,
        product_id: '',
        client_brand_code: '',
        p2p_online: '',
        uuid: '',
        device_id: '',
      }
    },
    tuyaInfo (item) {
      this.tuyaFormVisible = true
      this.tuyaform = item
      this.tuyaform.create_time = util.dateFormat(this.tuyaform.create_time)
      this.tuyaform.active_time = util.dateFormat(this.tuyaform.active_time)
      this.tuyaform.update_time = util.dateFormat(this.tuyaform.update_time)
    },
	getUserDetailByTuyaUid(tuyaUid) {
		this.api.UserManageList({
			page: 1,
			limit: 100,
			tuya_uid: tuyaUid
		}).then(res => {
			if (res.data.code == 200) {
				if(res.data.data.data && res.data.data.data.length > 0) {
					this.userInfo = res.data.data.data[0]
					this.$router.push('/iot/userinfo/'+ this.userInfo.uid + '&' + this.userInfo.region + '&tuya')
				}
				return
			}
			this.$message.error('获取失败' + res.data.msg)
		})
	}
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.container {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	.search {
		background: #F0F2F5;
		padding-top: 16px;
		margin-bottom: 24px;
	}
	.el-form {
		::v-deep(.el-form-item__label) {
			font-family: 'Manrope';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
            opacity: 0.8;
			padding-right: 0px;
		}
		.el-form-item {
			margin-left: 24px;
		}
	}
	.btnClass {
		color: #FFF;
		background-color: #FB6D07;
		border-color: #FB6D07;
	}
	.textBtn {
		color: #FB6D07;
	}
	::v-deep(thead) {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #000;
		opacity: 0.8;
		th {
			font-weight: 400;
			background-color: #F0F2F5;
		}
	}
  ::v-deep(tbody) {
    tr {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
    }
  }

}
</style>
